export const en = {
  'seo-title': '{page} - Parking application',
  'seo-description': 'Parking application',
  'page.unsupported-region': 'Unsupported Region',
  'page.login': 'Login',
  'page.pincode': 'PIN code',
  'page.root': 'Overview',
  'page.visitor.account': 'Visitor account',
  'page.favorites': 'Favorites',
  'page.new.parking.session': 'New parking session',
  'page.account.settings': 'Settings',
  'page.parking-sessions.active': 'Actieve sessions',
  'page.parking-sessions.planned': 'Planned sessions',
  'page.parking-sessions.success': 'New parking session successful',
  'menu.open.aria-label': 'Open main menu',
  'menu.close.aria-label': 'close main menu',
  'menu.sign-out': 'Sign out',
  'skip-link.label': 'To main content',
  back: 'Back',
  next: 'next',
  'offline-title': 'System is currently offline.',
  'offline-message': 'Try again later.',
  '404.intro.title': 'Page not found',
  '404.intro.subtitle': 'Unfortunately this page is not available',
  '404.intro.cta.label': 'Back to home',
  'unsupported-region.intro.title': 'You are outside the EU',
  'unsupported-region.intro.subtitle': 'This portal is not available outside the EU',
  'minute.long': '{value} minute',
  'minutes.long': '{value} minutes',
  'hour.long': '{value} hour',
  'hours.long': '{value} hours',
  'minute.short': '{value} min',
  'minutes.short': '{value} min',
  'hour.short': '{value} hour',
  'hours.short': '{value} hours',
  'hours.label': 'hour',
  'time.today': 'Today',
  'time.tomorrow': 'Tomorrow',
  and: 'and',
  warning: 'Let op!',
  'header.title': 'Aanmelden parkeren',
  'header.switch-language': 'Nederlands',
  'footer.contact.title': 'Contact information',
  'footer.contact.content.1':
    "Didn't you find what you were looking for? Call us at 14 020",
  'footer.contact.content.2':
    'Monday to Friday from 08.00 to 18.00. (From abroad: +31 20 624 1111)',
  'footer.contact.link.label.1': 'Contact information City of Amsterdam',
  'footer.contact.link.label.2': 'Or use our contact form',
  'footer.contact.link.url.1':
    'https://www.amsterdam.nl/en/contact-information/',
  'footer.contact.link.url.2':
    'https://formulieren.amsterdam.nl/TriplEforms/DirectRegelen/formulier/nl-NL/evAmsterdam/scContactEN.aspx',
  'footer.bottom.link.label.1': 'About this website',
  'footer.bottom.link.label.2': 'Privacy',
  'footer.bottom.link.label.3': 'Web archive',
  'footer.bottom.link.url.1':
    'https://www.amsterdam.nl/en/general-items/items-footer/about-website/',
  'footer.bottom.link.url.2':
    'https://www.amsterdam.nl/en/general-items/items-footer/privacy-city-of-amsterdam/',
  'footer.bottom.link.url.3': 'https://amsterdam.archiefweb.eu/',
  'progress.indicator.previous': 'Completed:',
  'progress.indicator.current': 'Current:',
  'progress.indicator.next': 'Upcomming:',
  permits: 'Your permits',
  'permits.filter-buttons.description': 'Which permit do you want to see?',
  'permits.filter-buttons.all': 'All',
  'permit.home.label': 'parking permit',
  'permit.home.link': 'http://amsterdam.nl/parkeren',
  'permit.information.title': 'Your permit details',
  'permit.permit-area': 'Permit area',
  'permit.price-per-hour': 'Your tarif',
  'permit.view-permit-area-link.label': 'Vergunninggebied bekijken',
  'permit.view-permit-area-link.url': 'https://www.amsterdam.nl/veelgevraagd/?productid=%7BDE09EE16-EC8F-4740-B007-1CF062E08D5E%7D',
  'login.title':
    'Welcome to the aanmeldenparkeren.amsterdam.nl portal of the City of Amsterdam',
  'login.description':
    'Here you can manage your parking session for your {link}.',
  'login.description.2': 'You login with your identification code and PIN.',
  'login.form.reportCode.label': 'Identification code',
  'login.form.reportCode.placeholder': 'Enter your identification code',
  'login.form.pinCode.label': 'PIN',
  'login.form.pinCode.placeholder': 'Enter your PIN',
  'login.form.pinCode.show.label': 'Show PIN',
  'login.form.pinCode.hide.label': 'Hide PIN',
  'login.button.label': 'Login',
  'login.forgotCode.description': 'Forgot your credentials?',
  'login.errorMessage.title': 'Entered credentials are incorrect',
  'login.errorMessage.body': 'Check your credentials and try again',
  'login.getInTouch.label': 'Request PIN',
  'pincode.title':
    'Welcome to the aanmeldenparkeren.amsterdam.nl portal of the City of Amsterdam',
  'pincode.description':
    'Here you can manage your parking session for your {link}.',
  'pincode.description.2': 'We send your PIN code to the registered phone number. For verification purposes we ask your identification code and last 4 digits of your phone number.',
  'pincode.form.reportCode.label': 'Identification code',
  'pincode.form.reportCode.placeholder': 'Enter your identification code',
  'pincode.form.phoneNumber.label': 'Last 4 digits of your phone number',
  'pincode.form.phoneNumber.placeholder': 'Enter the last 4 digits of your phone number',
  'pincode.button.label': 'Request PIN',
  'pincode.errorMessage.title': 'Something went wrong',
  'pincode.errorMessage.body': 'Try it again later',
  'pincode.login.label': 'I have my identification code and PIN and want to login',
  'pincode.successMessage.body': 'If the input data are correct you receive an SMS with your PIN.',
  'form.error.required': 'The required field {fieldName} is not filled in',
  'form.error.licence.format': 'This is not a valid license plate',
  'form.error.end-date.negative':
    'The enddate must be later than the starttime.',
  'parking.sessions': 'Parking sessions',
  'parking.sessions.active.title': 'Active',
  'parking.sessions.ended.title': 'Recently ended',
  'parking.sessions.planned.title': 'Planned',
  'parking.sessions.active.show-more': 'Show all active parking sessions',
  'parking.sessions.planned.show-more': 'Show all planned parking sessions',
  'parking.session.list.empty.title':
    'There are no active or planned parking sessions',
  'parking.session.list.empty.text':
    'Start or plan your new parking session for your visitor',
  'parking.session.button.add': 'New parking session',
  'parking.session.welcome': 'Welcome',
  'parking.session.new.progress.parking': 'Parking',
  'parking.session.new.progress.visitor': 'Visitor',
  'parking.session.new.progress.confirmation': 'Confirmation',
  'parking.session.new.progress.balance': 'Top up balance',
  'parking.session.new.progress.balance.short': 'Top up',
  'parking.session.form.licence-plate.label': 'License plate visitor',
  'parking.session.form.confirm': 'Confirm parking session',
  'parking.session.new': 'New parking session',
  'parking.session.step1.title': 'When does your visitor arrive?',
  'parking.session.step1.introduction': 'You choose your parking duration or configure the endtime of the parking session. If you visitor leaves earlier you can always cancel the parking session early and the remaining time and money will be added to your balance. \n' + 'Enter all information unless it is labeled with \'optional\'.',
  'parking.session.select-permit.title': 'For which permit do you want to start a parking session?',
  'parking.session.step1.zone.title': 'Choose the start and end time of paid parking',
  'parking.session.step1.zone.introduction': 'Your permit area {parkingZone} has multiple paid parking areas with different starting and end times. Not sure about the paid parking area? You can always edit your parking session.',
  'parking.session.step2.title': 'Who is visiting?',
  'parking.session.step2.introduction.regularPermit':
    'Enter the license plate for your parking session or choose from your favorite license plates.',
  'parking.session.step2.introduction.codePermit':
  'Enter the license plate for your parking session.',
  'parking.session.step2.introduction.assigned.plates.only': 'Chose one of your available license plates',
  'parking.session.step3.title': 'Top up balance',
  'parking.session.step3.introduction': 'Choose the amount with which you want to top up your balance.',
  'license.plate': 'License plate',
  'parking.session.form.back': 'back',
  'parking.session.form.save.license.label': 'Add to favorites',
  'parking.session.confirmation': 'Parking session confirmed',
  'parking.session.start': 'Your parking session has been started',
  'parking.session.planned': 'Your parking session has been planned.',
  'parking.session.stopped': 'Your parking session has been stopped.',
  'parking.session.adjust.end.time': 'Edit end time',
  'parking.session.adjust.new.end.time': 'New end time: {newEndTime}',
  'parking.session.adjust.extention.time': 'Extra time paid parking',
  'parking.session.adjust.extention.refund.time': 'Refund time',
  'parking.session.adjust.extention.refund.money': 'Refund money',
  'parking.session.no.balance.active.since.label': 'Active since {startDate}',
  'parking.session.unpaid.label': 'Your payment is being processed. This may take a few minutes. Refresh this page to see if your payment has been processed. This message will no longer be displayed afterward.',
  'parking.session.adjust.extention.button.submit': 'Confirm new end time',
  'parking.session.adjust.extention.payment.button.submit': 'Upgrade and extend session',
  'parking.session.adjust.extention.button.undo': 'Undo',
  'parking.session.adjust.extention.single.zone.notification.title': 'Paid parking: {startTime} - {endTime}',
  'parking.session.adjust.extention.single.zone.notification.label': 'Your visitors can park for free between {endTime} and {startTime}. You do not need to extend or plan a parking session during this time.',
  'parking.session.adjust.extention.selected.zone.notification.title': 'Time chosen for paid parking: {startTime} - {endTime}',
  'parking.session.adjust.extention.selected.zone.notification.label': 'Your visitors can park for free between {endTime} and {startTime}. You do not need to extend or plan a parking session during this time.',
  'parking.session.adjust.extention.different.zone.notification.label': 'Did your visitor park somewhere else with a different start and end time for paid parking?',
  'parking.session.adjust.extention.different.zone.submit': 'Save time paid parking',
  'parking.session.adjust.extention.change.zone.label': 'Change paid parking time',
  'parking.session.adjust.extention.low.balance.label': 'Balance deficit',
  'parking.session.adjust.low.time.balance.label': 'You still have {timeBalance} of parking time left, you cannot park longer than this time.',
  'parking.session.adjust.extention.form.error':
  'Unfortunately something went wrong while updating the parking session.',
  'parking.session.adjust.extention.earlier.zone.title': 'Your paid parking time is shorter than previously stated',
  'parking.session.form.error.low.time.balance.label': 'You still have {timeBalance} parking time left, you cannot park longer than this time.',
  'parking.session.form.error.low.money.balance.title': 'Top up money balance',
  'parking.session.form.error.low.money.balance.label': 'You need more money balance for this parking session. You can top up your money balance at the end of this process.',
  'parking.session.form.error.not.within.regime.title': 'Your visitors can park for free during this period',
  'parking.session.form.error.not.within.regime.label': 'Your visitors may park for free between {endTime} and {startTime}. You do not need to extend or plan a parking session during this time.',
  'parking.session.form.error.not.within.regime.full.day.label': 'In your chosen parkingzone in combination with the date parking is free between {startTime} and {endTime}.',
  'parking.session.form.error.maximun.sessions.reached.title': 'Already planned a parking session',
  'parking.session.form.error.maximun.sessions.reached.label': 'You can have a maximum of {maxAmount} parking sessions running at the same time. {maxAmount} parking sessions are already scheduled during the selected time. Schedule a session outside this time or adjust the parking sessions already planned.',
  'parking.session.form.back.to.overview.button.label': 'Back to your overview',
  'parking.session.form.error.title': 'Oops!',
  'parking.session.form.error.label':
    'Unfortunately something went wrong with starting your parking sessions',
  'parking.session.form.codevergunning.time.choose.custom': 'Choose your own end time and date',
  'parking.session.stop.error.label':
    'Unfortunately something went wrong with stopping your parking sessions',
  'parking-sessions.page.active.title': 'Active parking sessions',
  'parking-sessions.page.planned.title': 'Planned parking sessions',
  'parking-sessions.page.no.parking-sessions.found.title': 'No parking sessions found',
  'parking-sessions.page.no.parking-sessions.found.active': 'active',
  'parking-sessions.page.no.parking-sessions.found.planned': 'planned',
  'parking-sessions.page.no.parking-sessions.found.description': 'You have no planned parking session(s) for this license plate',
  'parking-sessions.page.search.input.label': 'Search by license plate:',
  'parking-sessions.page.search.input.placeholder': 'Licence plate',
  'parking-sessions.page.search.button.label': 'Search',
  'days.today': 'Today',
  'parking.balance.error.title': 'Oops!',
  'parking.balance.error.label':
    'Something went wrong with calculating your receipt.',
  'parking.balance.starttime': 'Start time',
  'parking.balance.endtime': 'End time',
  'parking.balance.paid.parking': 'Paid parking',
  'parking.balance.money.balance': 'Money balance',
  'parking.balance.money.balance.remaining': 'Remaining money balance',
  'parking.balance.time.balance': 'Time balance',
  'parking.balance.time.balance.remaining': 'Remaining time balance',
  'parking.balance.paid.parking.time': 'Parking duration',
  'parking.balance.parking.cost': 'Parking costs',
  'parking.balance.parking.cost.description':
    'Parking costs per hour {parkingCostsPerHour};  useful for {remainingParkingTime} of paid parking',
  'parking.session.from-til': 'Starting {startDate} until {endDate}',
  'parking.session.until': 'until',
  'parking.session.stop': 'Stop parking session',
  'parking.session.stop.notification':
    'Your parking session for license plate {vehicleId} has been stopped. The remaining time and money has been added to your balance.',
  'parking.session.addtional.details.created.date.time': 'Session planned: {createdTime}',
  'parking.session.addtional.details.duration': 'Paid parking: {duration}',
  'parking.session.addtional.details.cost': 'Your parking costs: {cost}',
  'parking.session.additional.details.no.balance.active.till.next': 'Your session will remain active until you start the next session with a different license plate.',
  'back.to.overview': 'Back to your overview',
  'parking.session.show.details': 'show more details',
  'parking.session.less.details': 'show less details',
  'parking.session.planned.edit': 'Modify',
  'parking.session.planned.stop': 'Remove',
  'parking.session.planned.stop.error': 'Unfortunately something went wrong while cancelling the parking session',
  'parking.session.progress.mobile': 'Step {currentProgress} of {steps}',
  'parking.session.progress.step1': 'Parking',
  'parking.session.progress.step2': 'Visitor',
  'parking.session.progress.step3': 'Top up balance',
  'parking.session.progress.step4': 'Confirmation',
  'parking.session.when.visiting': 'When does your visitor arrive?',
  'parking.session.when.visiting.details':
    'You choose your parking duration or configure the endtime of the parking session. If you visitor leaves earlier you can always cancel the parking session early and the remaining time and money will be added to your balance.',
  'parking.session.when.visiting.subtext':
    "Enter all information unless it is labeled with 'optional'.",
  'parking.session.form.start-day.label': 'Start date',
  'parking.session.form.start-time.label': 'Start time',
  'parking.session.form.end-day.label': 'End date',
  'parking.session.form.end-time.label': 'End time',
  'parking.session.form.parking-time.label': 'Parking duration',
  'parking.session.time-remaining': '{value} remaining',
  'overview.balance.title': ' Your balance',
  'overview.balance.time': ' Time',
  'overview.balance.money': ' Money',
  'overview.balance.money.remaining.time':
    'Useful for {remainingParkingTime} of paid parking',
  'overview.balance.no.cost.title': 'No balance applicable',
  'overview.balance.no.cost.description': 'Your {permitType} has no balance. The permit has no time restrictions',
  'top.up.balance.error.message': 'The iDeal payment has failed, no money has been deducted from your account. Try the payment again.',
  'top.up.balance.success.message': 'Your money balance has increased with {amount}.',
  'transaction.page.title': 'Parking history & Transactions',
  'transaction.page.got-a-question':
    'Have a question about a parking session? {link}',
  'transactions.page.no.transactions.found.heading': 'There is no transaction history available yet',
  'transaction.page.contact-us': 'Contact',
  'transaction.session.from': 'From',
  'transaction.session.to': 'To',
  'transaction.session.permit.caretaker.label': 'Caretaker visitor permit',
  'transaction.session.permit.label': "Visitor's permit",
  'transaction.session.visitor.label': 'Visitor {value}',
  'transaction.session.more-details': 'Show more details',
  'transaction.session.less-details': 'Show less details',
  'transaction.session.from.visitor': 'Created via visitor account',
  'transaction.session.cost': 'Parking cost',
  'transaction.session.duration': 'Time paid parking',
  'transaction.session.cost.extended': 'Parking cost',
  'transaction.topup.type': 'Cash balance {paymentOption} topped up',
  'transaction.refund.type': 'Cash balance recovered',
  'transaction.topup.amount.label': 'Top up amount',
  'transaction.refund.amount.label': 'Refunded amount',
  'transaction.reclaim.fees.label': 'Reclaim parking fees',
  'transaction.reclaim.fees.description': 'Do you disagree with the calculated parking costs and do you think something has gone wrong?',
  'transaction.reclaim.fees.link.label': 'You can read more about how to reclaim your parking fee here',
  'transaction.reclaim.fees.link.href': 'https://www.amsterdam.nl/parkeren-verkeer/parkeergeld-terugvragen/',
  'account.settings.personal.data': 'Personal information',
  'account.settings.personal.data.name': 'Name',
  'account.settings.personal.data.address': 'Address',
  'account.settings.personal.data.address.read.more':
    'More information for relocations',
  'account.settings.personal.data.address.description':
    'Do you want to report a move? Please contact us or read more on ',
  'account.settings.personal.data.address.link.label':
    'amsterdam.nl/parkeren.',
  'account.settings.personal.data.address.link.href':
    'https://www.amsterdam.nl/parkeren/',
  'account.settings.personal.data.modify.button': 'Change',
  'account.settings.personal.data.phonenumber': 'Register by phone',
  'account.settings.personal.data.phonenumber.call.to.register.session':
    'By calling {phoneNumber} (local rate) you can register your visit for a parking session via the drop-down menu.',
  'account.settings.personal.data.phonenumber.number.to.call.for.pin':
    '020 215 0077',
  'account.settings.personal.data.phonenumber.description':
    'Register your phone number and set a PIN for quick identification. We link your reporting code to the phone number. You no longer need to provide your reporting code. You register your phone number in My Parking.',
  'account.settings.personal.data.phonenumber.registered.phonenumber.description':
    'Do you want to change your phone number? You can do this in My Parking. Navigate to {link}.',
  'account.settings.personal.data.phonenumber.registered.phonenumber.label':
    'Register phonenumber',
  'account.settings.personal.data.phonenumber.register.phonenumber.link.label': 'parkeervergunningen.amsterdam.nl',
  'account.settings.personal.data.phonenumber.register.phonenumber.link.href': 'https://parkeervergunningen.amsterdam.nl/',
  'account.settings.personal.data.phonenumber.register.phonenumber.button.label':
    'Register phonenumber',
  'account.settings.personal.data.phonenumber.more.help.link.label':
    'More information about registering a visit by telephone',
  'account.settings.personal.data.phonenumber.more.help.link.href':
    'https://www.amsterdam.nl/parkeren-verkeer/parkeervergunning/parkeertijd-bezoek-doorgeven/',
  'account.settings.credentials': 'Credentials',
  'account.settings.credentials.reportCode': 'Identification code',
  'account.settings.credentials.pinCode': 'PIN',
  'account.settings.credentials.pinCode.form.title': 'Change PIN code',
  'account.settings.credentials.pinCode.form.pin.label': 'New PIN code',
  'account.settings.credentials.pinCode.form.pin.repeat.label': 'Repeat new PIN',
  'account.settings.credentials.pinCode.form.pin.placeholder': 'Enter your new PIN code',
  'account.settings.credentials.pinCode.form.success': 'Your PIN code has changed.',
  'account.settings.credentials.pinCode.form.error': 'Unfortunately something went wrong while updating the email address PIN code.',
  'account.settings.credentials.pinCode.form.pinCode.matches.error': 'PIN codes do not match',
  'account.settings.credentials.pinCode.form.button.submit': 'Change PIN code',
  'account.settings.credentials.pinCode.form.button.cancel': 'Cancel',
  'visitor.account.balance.title': 'Balance',
  'visitor.account.balance.time': 'Time balance',
  'visitor.account.parking.session.new': 'Parking session for {vehicleId}',
  'visitor.account.parking.session.step1.title': 'How long do you want to park?',
  'visitor.account.parking.session.step1.introduction1': 'You choose the parking time or set an end time. If you still want to stay longer, you can always extend your parking session.',
  'visitor.account.parking.session.step1.introduction2': 'Fill in all information unless it says "optional". This information is not mandatory to fill in.',
  'visitor.account.parking.session.step1.remaining.time.warning': 'You have {remainingTime} time balance left, you cannot park longer than this time.',
  'visitor.account.parking.session.progress.step1': 'Parking',
  'visitor.account.parking.session.progress.step2': 'Payment',
  'visitor.account.parking.session.progress.step3': 'Confirmation',
  'visitor.account.parking.balance.parking.cost.description': 'Parking costs per hour {parkingCostsPerHour}',
  'visitor.account.parking.time.start.now': 'Start session now',
  'visitor.account.parking.time.start-time.label': 'Start time',
  'visitor.account.parking.time.end-time.label': 'End time',
  'visitor.account.parking.time.parking-time.label': 'Parking time',
  'visitor.account.parking.time.choose.custom': 'Choose your own end time and date',
  'visitor.account.parking.time.1800000': '30 min',
  'visitor.account.parking.time.3600000': '1 hour',
  'visitor.account.parking.time.7200000': '2 hours',
  'visitor.account.parking.time.10800000': '3 hours',
  'visitor.account.parking.sessions.error.label':
  'Unfortunately, something went wrong while retrieving your parking sessions',
  'page.transactions': 'History',
  'page.balance.top.up': 'Top up balance',
  'permit.visitor-account.title': 'Visitor account',
  'permit.visitor-account.login': 'Credentials',
  'permit.visitor-account.login.report-code': 'Identification code',
  'permit.visitor-account.login.pin-code': 'PIN',
  'permit.visitor-account.login.copy': 'Copy credentials',
  'permit.visitor-account.login.copied': 'The credentials have been copied to your clipboard',
  'permit.visitor-account.time-balance.title': 'Allocated time balance',
  'permit.visitor-account.login.description': 'Your visitors can login via {link}',
  'permit.visitor-account.login.link.href': 'https://aanmeldenparkeren.amsterdam.nl',
  'permit.visitor-account.login.link.label': 'aanmeldenparkeren.amsterdam.nl',
  'permit.visitor-account.page.link.label': 'Edit',
  'form.input.text.clear.button': 'delete',
  'parking.session.form.licence-plate.description': 'License plate visitor',
  'parking.session.form.visitor.name.label': 'Visitor name',
  'parking.session.form.visitor.name.placeholder': 'Input a visitor name',
  'parking.session.adjust.find.zone.link.label': 'Where can I find my start and end time of paid parking?',
  'parking.session.adjust.find.zone.link.url': 'https://www.amsterdam.nl/parkeren-verkeer/parkeertarieven/',
  'parking.session.form.license.plates.error.label': 'Something went wrong with retrieving your license plates',
  'parking.session.addtional.details.created.time': 'Session planned: {createdTime}',
  'parking.session.form.licenseplates.manual.input': 'Input a license plate',
  'parking.session.form.licenseplates.favourite.label': 'Or choose a license plate from your favorites',
  'parking.session.form.licenseplates.favourite.assigned.plates.only.label': 'Choose a license plate from your favorites',
  'parking.session.form.licenseplates.favourite.limit.exceeded': 'You have already saved {limit} license plates in your favourites. Go to favorites to adjust this.',
  'parking.session.form.licenseplates.invalid.error': 'Input a license plate or choose one of your favorites to continue.',
  'favorites.title': 'License plate favorites',
  'favorites.description': 'Your favorite visitors and their license plates are displayed here, such that you can start a parking session easily.',
  'favorites.button.add': 'Add license plate to favorites',
  'favorites.maximum.limit.reached.title': 'Maximum number reached',
  'favorites.maximum.limit.reached.description': 'You can store a maximum of {maximumAmount} license plates. If you want to add a new one, first delete a license plate.',
  'favorites.limit.reached.title': 'Maximum number of favorties reached',
  'favorites.limit.reached.description': 'Do you want to replace a license plate? Contact municipality Amsterdam',
  'favorites.link.email': ' ',
  'favorites.delete.confirmation': 'Favorite has been removed.',
  'favorites.add.confirmation': 'Favorite has been added',
  'favorites.cannot.add.title': 'Add favorite',
  'favorites.cannot.add.description': 'Do you want to add or replace a license plate? Contact municipality Amsterdam',
  'favorites.button.submit': 'Add',
  'favorites.button.cancel': 'Cancel',
  'favorites.button.delete': 'Delete',
  'favorites.form.error.title': 'Oops!',
  'favorites.fetch.error.label': 'Something went wrong with retrieving your favorites',
  'favorites.form.error.label': 'Something went wrong with adding your favorite',
  'favorites.form.delete.error.label': 'Something went wrong with deleting your favorite',
  'favorites.form.title': 'Add license plate to favorites',
  'transaction.session.creationTime': 'Session planned: {value}',
  'transaction.session.updateTime': 'Edited at: {value}',
  'transaction.session.regimeTime': 'Paid parking: {value}',
  'transaction.session.cancelled.label': 'Cancelled',
  'visitor.account.settings.description': 'With a visitor account your visitors can plan and pay their parkingsession. You allocate a part of your time balance to your visitor account.',
  'visitor.account.settings.description2': 'Your vistior can login to the aanmelden parkeren platform with the credentials mentioned below.',
  'visitor.account.settings.balance.title': 'Balance',
  'visitor.account.settings.login.details.title': 'Credentials',
  'visitor.account.settings.login.details.copy': 'Copy credentials',
  'visitor.account.settings.login.details.edit': 'Edit',
  'visitor.account.settings.visitor.login.description': 'Your visitor can login via ',
  'visitor.account.settings.visitor.login.link': 'https://aanmeldenparkeren.amsterdam.nl',
  'visitor.account.settings.visitor.login.link.label': 'aanmeldenparkeren.amsterdam.nl',
  'visitor.account.settings.form.title': 'Edit PIN',
  'visitor.account.settings.form.button.submit': 'Edit',
  'visitor.account.settings.form.button.cancel': 'Cancel',
  'visitor.account.settings.form.pinCode.label': 'New PIN',
  'visitor.account.settings.form.error': 'Something went wrong with updating your PIN',
  'visitor.account.settings.form.pinCode.description': '4 digits',
  'visitor.account.settings.form.pinCode.length.error': 'The field New PIN has to be 4 digits',
  'visitor.account.settings.form.pinCode.placeholder': 'Enter your new PIN',
  'visitor.account.settings.form.pinCodeRepeat.label': 'Repeat your PIN',
  'visitor.account.settings.form.pinCodeRepeat.placeholder': 'Repeat your PIN',
  'visitor.account.settings.form.success': 'Your PIN has been succesfully changed',
  'visitor.account.settings.copy.success': 'Your credentials have been copied',
  'visitor.account.settings.reportCode': 'Identification code',
  'visitor.account.settings.pinCode': 'PIN',
  'visitor.account.settings.allocate.title': 'Allocate time balance',
  'visitor.account.settings.reclaim.title': 'Reclaim allocated time balance',
  'visitor.account.settings.allocate.description': 'You allocate a part of your time balance to your visitor account. The allocated time is reduced from your permits time balance.',
  'visitor.account.settings.reclaim.description': 'You can reclaim the allocated time balance to your visitor account. The reclaimed time balance will be added to your permits time balance.',
  'visitor.account.settings.allocate.time.available': 'Available time balance on your account: {availableTime}',
  'visitor.account.settings.allocate.time.7200000': '2 hours',
  'visitor.account.settings.allocate.time.10800000': '3 hours',
  'visitor.account.settings.allocate.time.14400000': '4 hours',
  'visitor.account.settings.allocate.time.28800000': '8 hours',
  'visitor.account.settings.allocate.time.43200000': '12 hours',
  'visitor.account.settings.allocate.confirmation': '{timeAdded} has been added to your visitor account. The hours have been reduced on your visitors money balance.',
  'visitor.account.settings.reclaim.confirmation': 'Time balance {timeReclaimed} has been reclaimed from your visitors account. The hours have been added to your permit. ',
  'visitor.account.settings.allocate.error.description': 'Something went wrong with transfering the money balance',
  'visitor.account.settings.allocate.button.submit': 'Allocate',
  'visitor.account.settings.reclaim.button.submit': 'Reclaim',
  'visitor.account.settings.allocate.button.cancel': 'Cancel',
  'account.settings.personal.data.phonenumber.form.error': 'All fields are mandatory',
  'top.up.balance.form.amount.title': 'Choose a value',
  'top.up.balance.form.amount.description': 'Choose a value to top up your balance.',
  'top.up.balance.form.amount.error': 'Select an amount to continue',
  'top.up.balance.form.payment.details.title': 'Credentials',
  'top.up.balance.form.payment.details.description': 'Choose your bank to pay with iDeal.',
  'top.up.balance.form.step1.title': 'Top up balance',
  'top.up.balance.form.step1.title.success': 'Money balance has been topped up',
  'top.up.balance.form.step2.title': 'Confirmation',
  'top.up.balance.form.submit': 'Pay now',
  'top.up.balance.money.balance': 'Money balance',
  'top.up.balance.money.new.balance': 'New money balance',
  'top.up.balance.top.up.amount': 'Balance to be topped up',
  'top.up.balance.error.title': 'Payment failed',
  'top.up.balance.pending.message': 'We are processing your order. This can take a few minutes.',
  'visitor.account.discount': '{percentage} percent discount. Regular tarif {value}',
  'visitor.account.permit.information.title': 'Permit area',
  'visitor.account.name': 'Visitor',
  'visitor.account.welcome': 'Hi visitor!',
  'visitor.account.parking.title': 'Parking',
  'visitor.account.license.plate.form.vehicleId.label': 'Enter your license plate',
  'visitor.account.license.plate.form.vehicleId.description': 'Enter your license plate without dashes, only numbers and letters.',
  'visitor.account.license.plate.form.vehicleId.placeholder': 'License plate',
  'visitor.account.license.plate.form.submit.button': 'Confirm license plate',
  'visitor.account.license.plate.form.submit.error': 'The entered license plate does not match our registration.',
  'visitor.account.settings.form.old.pinCode.label': 'Current PIN code',
  'visitor.account.settings.form.old.pinCode.placeholder':
  'Enter your current PIN code',
  'visitor.account.settings.form.invalid.pin.error':
  'Current PIN code is invalid.',
  'login.page.payment.error.message': 'Because of disturbance it is not possible to top up your money balance. Our apologies for the inconvenience.',
}
